import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core'



const Chip = props => {

  const useStyles = makeStyles({
    chip: {
      backgroundColor: `${process.env.AUX_COLOR_COMPLEMENT}`,
      border: `solid 1px ${process.env.AUX_COLOR}`,
      marginRight: '5px',
      marginBottom: '5px',
      padding: '8px',
      borderRadius: '20px',
      fontSize: '18px',
      textTransform: 'lowercase',
      '& a': {
        textDecoration: 'none',
        color: `${process.env.COLOR1}`
      }
    }
  });

  const classes = useStyles()
  return (
    <span className={classes.chip}>
      <Link to={props.link}>{props.children}</Link>
    </span>
  )
}

export default Chip