import React from 'react'
import { Breadcrumbs, withStyles } from '@material-ui/core'

const CssBreadcrumbs = withStyles({
    root: {
      '& .MuiTypography-body1': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`
      },
      '& .MuiBreadcrumbs-root': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`
      },
      '& .MuiBreadcrumbs-ol': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`
      },
      '& .MuiBreadcrumbs-li': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`
      },
      '& .MuiBreadcrumbs-separator': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`
      },
    },
  })(Breadcrumbs)

const CustomBreadcrumbs = props => (
    <CssBreadcrumbs aria-label="breadcrumb">
        {props.children}
    </CssBreadcrumbs>
)

export default CustomBreadcrumbs