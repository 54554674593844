import React, { useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { MenuItem, TextField, makeStyles, withStyles } from '@material-ui/core'

const Categories = props => {

  const data = useStaticQuery(graphql`
    query {
      allCategory(sort: {fields: title, order: ASC}) {
        nodes {
          id
          title
          slug
        }
      }
    }
  `)

  const categories = data.allCategory.nodes

  const [category, setCategory] = useState('')

  const handleChange = (event) => {
    setCategory(event.target.value);
    console.log(event.target.value)
    navigate(`/categorias/${event.target.value}`)
  };

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: `${process.env.COLOR1}`,
      },
      '& label': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `20px`
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${process.env.COLOR1}`,
      },
      '& .MuiInputBase-input': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${process.env.COLOR1}`,
        },
        '&:hover fieldset': {
          borderColor: `${process.env.COLOR1}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${process.env.COLOR1}`,
        },
      },
    },
  })(TextField);

  const useStyles = makeStyles({
    center: {
      textAlign: `center`,
    },
    left: {
      textAlign: `left`,
    }
  });

  const classes = useStyles()

  return (
    <div className={props.align === 'left' ? classes.left : classes.center}>
      <CssTextField
        select
        label="Categorias"
        value={category}
        onChange={handleChange}
        helperText="Selecione uma categoria para visualizar os produtos"
        variant="outlined"
      >
        {categories.map(category => (
          <MenuItem key={category.id} value={category.slug}>{category.title}</MenuItem>
        ))}
      </CssTextField>
    </div>
  )
}

export default Categories